import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";

// Import your mock data
import { mockOAuthResponse } from "../mock/mockOAuthResponse";

const gatewayUrl = "https://stage-gateway.orders.co/wix/provider/oauth2";

function OAuthRedirect() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");
    const mock = urlParams.get("mock");
    const isMock = mock === "true";

    if (!code) {
      setErrorMessage("Authorization code not found.");
      setOpenSnackbar(true);
      setLoading(false);
      return;
    }

    const handleSuccess = (response) => {
      const { auth, locations } = isMock ? response.body : response.data.body;

      localStorage.setItem("auth", JSON.stringify(auth));

      if (locations) {
        localStorage.setItem("locations", JSON.stringify(locations));
        navigate(`/locations${isMock ? "?mock=true" : ""}`);
      } else {
        if (isMock) {
          // For mock data, we can proceed directly
          navigate(`/locations${isMock ? "?mock=true" : ""}`);
        } else {
          // Fetch locations if not provided
          axios
            .post(gatewayUrl, {
              code,
              state,
              accessToken: auth.accessToken,
            })
            .then((res) => {
              const locations = res.data.body.locations;
              const instanceId = res.data.body.instanceId;
              localStorage.setItem("instanceId", instanceId);
              localStorage.setItem("locations", JSON.stringify(locations));
              navigate("/locations");
            })
            .catch((error) => {
              console.error(error);
              setErrorMessage(
                "We are unable to complete the setup. Please contact support at 855-500-1070 option 4 to finish the onboarding."
              );
              setOpenSnackbar(true);
              setLoading(false);
            });
        }
      }
    };

    const handleError = (error) => {
      console.error(error);
      setErrorMessage(
        "We are unable to complete the setup. Please contact support at 855-500-1070 option 4 to finish the onboarding."
      );
      setOpenSnackbar(true);
      setLoading(false);
    };

    if (isMock) {
      // Simulate API call with mock data
      setTimeout(() => {
        handleSuccess(mockOAuthResponse);
        setLoading(false);
      }, 1000);
    } else {
      axios
        .post(gatewayUrl, {
          code,
          state,
        })
        .then(handleSuccess)
        .catch(handleError);
    }
  }, [navigate]);

  return (
    <Box sx={{ p: { xs: 2, md: 3 } }}>
      {loading ? (
        <>
          <CircularProgress />
          <Typography variant="h6" mt={2}>
            Redirecting...
          </Typography>
        </>
      ) : null}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default OAuthRedirect;
